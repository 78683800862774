import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState, } from 'react'
//import { MenuItem, Select } from '@mui/base';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ColorPicker, ColorBox } from "material-ui-color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { saveWebsiteResource, showSuccessToast, showErrorToast, getImageData, appendScriptToHeadOrBody, updateCssJsonWrtThemeId, showWarningToast, getLogoWidthAndHeight } from './Utils';
import "./GlobalSettingComponent.css"
import { SITE_LANGUAGES } from './constants';

function GlobalSettingComponent(props) {

  const [themeJson, setThemeJson] = useState(props.themeJson);
  const [expanded, setExpanded] = useState([false, false, false, false]);
  const [socialPlatformLists, setSocialPlatformList] = useState(props.siteResource.socialPlatformList);
  const [clicked, setClicked] = useState(true);
  const [subexpandedAccordion, setSubExpandedAccordion] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  
  const facebookLabel = "Facebook";
  const twitterLabel = "Twitter";
  const youTubeLabel = "YouTube";
  const linkedInLabel = "LinkedIn";
  const instagramLabel = "Instagram";
  const pinterestLabel = "Pinterest";
  const playStoreLabel = "PlayStore";

  const [formData, setFormData] = useState({
    ...props.siteResource,
  });


  const updateThemeJson = (newThemeJson) => {
    setThemeJson(newThemeJson);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    const chars = panel.split('');
    const lastDigit = chars[chars.length - 1];
    const value = isExpanded ? panel : false;
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[lastDigit - 1] = value;
      return newState;
    });
  };

  const handleColorInputChange = (name, colorResult) => {
    let hexColor = "";
    if (colorResult.css) {
      hexColor = colorResult.css.backgroundColor;
    }
    handleInputChange(name, hexColor);
  };

  const handleUrlChange = (name, newUrl) => {
    const index = socialPlatformLists.findIndex(platform => platform.name.toLowerCase() === name.toLowerCase());

    if (index !== -1) {
      const updatedPlatform = { ...socialPlatformLists[index], url: newUrl };
      const updatedList = [...socialPlatformLists];
      updatedList[index] = updatedPlatform;

      setSocialPlatformList(updatedList);
      handleInputChange(name, newUrl);
      handleInputChange('socialPlatformList', updatedList);
    }
    else {
      const newPlatform = { name: name, url: newUrl };
      const newList = [...socialPlatformLists, newPlatform];

      setSocialPlatformList(newList);
      handleInputChange(name, newUrl);
      handleInputChange('socialPlatformList', newList);
    }
  };

  // const handleInputChange = (name, value) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: (value),
  //   }));
  // };

  // const handleInputChange = (name, value) => {
  //     setFormData( (prevData) => {

  //       const keysForWebStory = ["webStoryAds", "ampHeaderScript", "adsSlots", "webstoryLogo" , "webstoryDefaultAuthor", "webstoryDefaultAgencyName","ctaDesc", "ctaBgColor","ctaBtnText","ctnBtnBgColor","ctaNextSlide","ctaNextSlideMsg"];
  //       const keysForImmersiveStory = ["immStoryGa4Id", "immStoryGaId", "immStoryLogoHeight"];
        
  //       //Checking for immersive story
  //       if(keysForImmersiveStory.includes(name)) {
  //         // setImmersivestoryFlag(true);
  //         // handleInputChange("updateFlagImmersiveStory", immersivestoryFlag);
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           updateFlagImmersiveStory: true
  //         }));
  //       }

  //       if(keysForWebStory.includes(name)) {
  //         // setWebstoryFlag(true);
  //         // webstoryFlag = true;
  //         // handleInputChange("updateFlagWebStory", webstoryFlag);
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           updateFlagWebStory: true
  //         }));
  //       }

  //       if (name == "webstoryLogo") {

  //         if(value != null && value != "") {
  //           const jsonResponse = getLogoWidthAndHeight(value);
  
  //           if (jsonResponse != undefined && jsonResponse != null && jsonResponse.originalImageHeightPx != null && jsonResponse.originalImageWidthPx != null) {
  
  //             setFormData((prevData) => ({
  //               ...prevData,
  //               webstoryLogoWidth: jsonResponse.originalImageWidthPx,
  //               webstoryLogoHeight: jsonResponse.originalImageHeightPx
  //             }));
  //           }
  //         }
  //       }
        
  //       console.log("formdata" , formData);

  //       return {
  //         ...prevData,
  //         [name]: value,
  //       };
  //     })
  // }

  const handleInputChange = (name, value) => {
    setFormData((prevData) => {
      const keysForWebStory = ["webStoryAds", "ampHeaderScript", "adsSlots", "webstoryLogo", "webstoryDefaultAuthor", "webstoryDefaultAgencyName", "ctaDesc", "ctaBgColor", "ctaBtnText", "ctnBtnBgColor", "ctaNextSlide", "ctaNextSlideMsg", "gaId", "ga4Id","webstoryCustomSlidePosition"];
      const keysForImmersiveStory = ["immStoryLogo", "immStoryGa4Id", "immStoryGaId", "immStoryLogoHeight","webstoryNextSlideCta"];

      let newData = { ...prevData, [name]: value };

      if (keysForImmersiveStory.includes(name)) {
        newData.updateFlagImmersiveStory = true;
      }

      if (keysForWebStory.includes(name)) {
        newData.updateFlagWebStory = true;
      }

      if (name === "webstoryLogo" && value) {
        const jsonResponse = getLogoWidthAndHeight(value);

        if (jsonResponse && jsonResponse.originalImageHeightPx && jsonResponse.originalImageWidthPx && (jsonResponse.originalImageHeightPx != "null" || jsonResponse.originalImageWidthPx != "null")) {
          newData.webstoryLogoWidth = jsonResponse.originalImageWidthPx;
          newData.webstoryLogoHeight = jsonResponse.originalImageHeightPx;
        } else {
          showErrorToast("Please enter valid webstory Logo.  Either Logo Image is Corrupt or MSID is not correct");
        }
      }

      if (name === "immStoryLogo" && value) {
        const jsonResponse = getLogoWidthAndHeight(value);

        if (jsonResponse && jsonResponse.originalImageHeightPx && jsonResponse.originalImageWidthPx && (jsonResponse.originalImageHeightPx != "null" || jsonResponse.originalImageWidthPx != "null")) {
          newData.immStoryLogoHeight = jsonResponse.originalImageHeightPx
        }
        else {
          showErrorToast("Please enter valid Immersive Story Logo. Either Logo Image is Corrupt or MSID is not correct");
        }
      }

      if (name === "mainLogo" && value) {
        const jsonResponse = getLogoWidthAndHeight(value);

        if (jsonResponse && jsonResponse.originalImageHeightPx && jsonResponse.originalImageWidthPx) {
          newData.imageWidth = jsonResponse.originalImageWidthPx;
          newData.imageHeight = jsonResponse.originalImageHeightPx;
        }
      }

      return newData;
    });
  };

  const encodeHTML = (text) => {
    const element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML;
  };

  const handleScriptChange = (name, value) => {
    let jsonObj = {}; 
    if(props.themeJson && props.themeJson != '') {
      jsonObj = props.themeJson;
    }
    
    if (name === "headerScript") {
      jsonObj.globalHeaderScript = value.trim();
      //appendScriptToHeadOrBody("header_script", value.trim());
      handleInputChange("headerScript", value);
    }
    if (name === "bodyScript") {
      jsonObj.globalBodyScript = value.trim();
      //appendScriptToHeadOrBody("body_script", value.trim());
      handleInputChange("bodyScript", value);
    }
    updateThemeJson(jsonObj);
  }
  const getUrlByName = (name) => {
    const entry = props.siteResource.socialPlatformList.find(item => item.name.toLowerCase().includes(name.toLowerCase()));
    return entry ? entry.url : '';
  };

  function isValidHTML(htmlString) {
    if (htmlString == "" || htmlString == null)
      return true;
    const validTagRegex = /^<([a-z]+)([^<]*?)(?:(?:"[^"]*"|'[^']*'|[^'">])*)?>(.*)<\/\1>\s*$/i;
    return validTagRegex.test(htmlString);
  }
  function hasScriptAttributes(scriptString) {
    if (scriptString == "" || scriptString == null) {
      return true;
    }
    const attributesRegex = /\b(?:async|defer|type\s*=\s*["']\s*module\s*["'])\b/g;
    const matches = scriptString.match(attributesRegex) || [];
    return matches.includes('async') || matches.includes('defer') || matches.includes('type="module"');
  }

  function validateImg(type, msId) {
    if (msId == "" || msId == null)
      return true;

    let data = getImageData(msId);

    if (data && data.items.length > 0) {
      if (data.items[0] && data.items[0].tn == "IMAGES") {
        if (type === "schemaLogo" && data.items[0] && +data.items[0].imageHeight < 112 && +data.items[0].imageWidth < 112) {
          showErrorToast("Schema Logo should be more than 112*112px");
          return false;
        }
        return true;
      }
      else {
        switch(type) {
          case "immStoryLogo":
            showErrorToast("Please enter valid Immersive Story Logo Id");
            return false;
          case "webstoryLogo":
            showErrorToast("Please enter valid Webstory Logo Id");
            return false;
          default:
            showErrorToast("Please enter valid " + type + " Id");
            return false;
        }
      }
    } else {
        switch(type) {
          case "immStoryLogo":
            showErrorToast("Please enter valid Immersive Story Logo Id");
            return false;
          case "webstoryLogo":
            showErrorToast("Please enter valid Webstory Logo Id");
            return false;
          default:
            showErrorToast("Please enter valid " + type + " Id");
            return false;
        }
    }
  }

  function validateMsid(type, msId) {
    if (msId == "" || msId == null)
      return true;

    let data = getImageData(msId);
    if (!(data && data.items.length > 0)) {
      showErrorToast("Please enter valid msid in (" + type + ") static page");
    }
  }

  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }
  async function handleButtonClick() {

    if (formData.ctaDesc == "" || formData.ctaDesc == null) {
      showErrorToast("Please enter CTA Description");
      return false;
    }
    if (formData.ctaBtnText == "" || formData.ctaBtnText == null) {
      showErrorToast("Please enter CTA Text");
      return false;
    }
    if (formData.websiteTitle == "" || formData.websiteTitle == null) {
      showErrorToast("Please enter Website Title");
      return false;
    }

    if (!isValidHTML(formData.headerScript)) {
      showErrorToast("Enter a valid Header Script");
      return false;
    }
    if (!isValidHTML(formData.bodyScript)) {
      showErrorToast("Enter a valid Body Script");
      return false;
    }
    if (!isValidHTML(formData.adsSlots)) {
      showErrorToast("Enter a valid Amp Body Script");
      return false;
    }
    if (!isValidHTML(formData.ampHeaderScript)) {
      showErrorToast("Enter a valid Amp Header Script");
      return false;
    }
    if (!hasScriptAttributes(formData.headerScript)) {
      showErrorToast("Header Script must include async, defer, type=\"module\"");
      return false;
    }
    if (!hasScriptAttributes(formData.bodyScript)) {
      showErrorToast("Body Script must include async, defer, type=\"module\"");
      return false;
    }
    if (!hasScriptAttributes(formData.adsSlots)) {
      showErrorToast("Amp Body Script must include async, defer, type=\"module\"");
      return false;
    }
    if (!hasScriptAttributes(formData.ampHeaderScript)) {
      showErrorToast("Amp Header Script must include async, defer, type=\"module\"");
      return false;
    }

    if (formData.mainLogo == null || formData.mainLogo == "") {
      showErrorToast("Please enter Main Logo");
      return false;
    }
    else if (!validateImg("mainLogo", formData.mainLogo)) {
      //  showErrorToast("Please enter valid Favicon Id");
      return false;
    }

    if (formData.favicon == null || formData.favicon == "") {
      showErrorToast("Please enter Favicon");
      return false;
    }
    else if (!validateImg("favicon", formData.favicon)) {
      // showErrorToast("Please enter valid Logo Id");
      return false;
    }
    if (!validateImg("fallbackImage", formData.fallbackImage)) {
      //showErrorToast("Please enter valid fallbackImage Id");
      return false;
    }
    if (formData.schemaLogo == null || formData.schemaLogo == "") {
      showErrorToast("Please enter Schema Logo");
      return false;
    }
    else if (!validateImg("schemaLogo", formData.schemaLogo)) {
      //showErrorToast("Please enter valid schemaLogo Id");
      return false;
    }
    if (formData.siteLanguage == null || formData.siteLanguage == "") {
      showErrorToast("Please enter Website Language");
      return false;
    }

    if(formData.webstoryLogo == null || formData.webstoryLogo == "") {
      showErrorToast("Please enter Webstory Logo");
      return false;
    
    } else if (!validateImg("webstoryLogo", formData.webstoryLogo)) {
      return false;
    }

    if(formData.webstoryDefaultAuthor == null || formData.webstoryDefaultAuthor == "") {
      showErrorToast("Please enter Webstory Default Author");
      return false;
    }

    if(formData.webstoryDefaultAgencyName == null || formData.webstoryDefaultAgencyName == "") {
      showErrorToast("Please enter Webstory Default Agency Name");
      return false;
    }

    if( !(formData.webstoryCustomSlidePosition== null || formData.webstoryCustomSlidePosition == "") && Number.isNaN(+formData.webstoryCustomSlidePosition)) {
      showErrorToast("Please enter valid Custom Slide Position. It should be number only");
      return false;
    }

    if(formData.immStoryLogo == null || formData.immStoryLogo == "" || formData.immStoryLogo == "null") {
      showErrorToast("Please enter Immersive Story Logo");
      return false;
    
    } else if (!validateImg("immStoryLogo", formData.immStoryLogo)) {
      return false;
    }

    if(formData.immStoryLogoHeight == null || formData.immStoryLogoHeight == "") {
      showErrorToast("Please enter Immersive Story Logo height");
      return false;
    }

    if(formData.aboutSiteContent != null && formData.aboutSiteContent != "" && formData.aboutSiteContent.length >= 200) {
      showErrorToast("About site content length should be less than 200 characters");
      return false;
    }

    if (formData.staticPages) {
      const staticPageError = formData?.staticPages.filter((item) => item.name === '' || item.sourcePath === '' || item.destinationPath === '' || item.destinationPath === '/staticpage/.html')
      if (staticPageError.length > 0) {
        showErrorToast("Please enter valid details in static page.")
        return false;
      }
      const staticPageSourcePath = formData?.staticPages.filter((item) => !item.sourcePath.startsWith('/'))
      if (staticPageSourcePath.length > 0) {
        let errorString = `The Short Url doesn't start with '/' for `;
        staticPageSourcePath.map((item,) => {
          errorString += `${item.name}, `;
        })
        const errorString1 = errorString.substring(0, errorString.length - 2);
        showErrorToast(errorString1);
        return false;
      }
      const boolinan = checkForDuplicateSourcePath(formData?.staticPages);
      if (!boolinan) {
        return false;
      }
      const msidArray = formData?.staticPages.map(obj => {
        const match = obj.destinationPath.match(/\/staticpage\/(\d+)\.html/);
        return match ? match[1] : null;
      });
      console.log("msidArray", msidArray);
      let data1 = getImageData(msidArray.join(","));
      console.log("data1", data1);
      if (data1 && data1.items.length !== msidArray.length) {
        showErrorToast("Please enter valid msids in static pages.")
        return false;
      }
    }

    setClicked(true);
    try {
      setIsSaving(true);
      const responseee = await saveWebsiteResource(finalFormDataToSend);    
      await updateCssJsonWrtThemeId(1, themeJson, props.themeCss);
      await updateCssJsonWrtThemeId(2, themeJson, props.themeCss);
      
      if(responseee) {
        setFormData(responseee);
      }
      
      showSuccessToast("Global Settings Saved Successfully");

      setFormData((prevData) => ({
        ...prevData,
        updateFlagImmersiveStory: false
      }));

      setFormData((prevData) => ({
        ...prevData,
        updateFlagWebStory: false
      }));

    } catch (e) {
      console.error('Call saveGLobalSettings failed with status: ' + e);
      showErrorToast("Some Went Wrong, Please try again later!");

    } finally {
      setIsSaving(false);
      setClicked(false);
    }
  }

  function checkForDuplicateSourcePath(errors) {
    const errorNames = new Set();
    const errorSource = new Set();

    for (const error of errors) {
      if (errorNames.has(error.name)) {
        showErrorToast(`Duplicate name found in static pages: ${error.name}`)
        return false;
      }
      if (errorSource.has(error.sourcePath)) {
        showErrorToast(`Duplicate source path found in static pages: ${error.sourcePath}`)
        return false;
      }
      errorNames.add(error.name);
      errorSource.add(error.sourcePath);
    }
    console.log("No duplication found in static pages.");
    return true;
  }

  // const handleNewline = (e) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault(); // Prevents the actual newline character from being inserted
  //     return e.target.value + '\n';
  //   } else {
  //     return e.target.value;
  //   }
  // };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      headerScript: props.themeJson.globalHeaderScript,
      bodyScript: props.themeJson.globalBodyScript,
      adNetwork: formData.adNetwork,
      websiteTitle: formData.websiteTitle,
      tagline: formData.tagline,
      siteDescription: formData.siteDescription,
      mainLogo: formData.mainLogo,
      favicon: formData.favicon,
      fallbackImage: formData.fallbackImage,
      schemaLogo: formData.schemaLogo,
      adsTxtFile: formData.adsTxtFile,
      robotsTxt: formData.robotsTxt,
      copyRightContent: formData.copyRightContent,
      siteLanguage: formData.siteLanguage,
      imageAgencyLabel: formData.imageAgencyLabel,

      webstoryLogo: formData.webstoryLogo,
      webstoryLogoWidth: formData.webstoryLogoWidth,
      webstoryLogoHeight: formData.webstoryLogoHeight,
      webStoryAds: formData.webStoryAds,
      ampHeaderScript: formData.ampHeaderScript,
      ampBodyScript: formData.adsSlots,
      ctaDesc: formData.ctaDesc,
      ctaBtnText: formData.ctaBtnText,
      ctaBgColor: formData.ctaBgColor,
      ctnBtnBgColor: formData.ctnBtnBgColor,
      ctaNextSlide: formData.ctaNextSlide,
      socialPlatformList: socialPlatformLists,
      // imageHeight: formData.imageHeight,
      // imageWidth: formData.imageWidth,
      webstoryDefaultAuthor: formData.webstoryDefaultAuthor,
      webstoryDefaultAgencyName: formData.webstoryDefaultAgencyName,
      gaId: formData.gaId,
      ga4Id: formData.ga4Id,
      webstoryCustomSlidePosition: formData.webstoryCustomSlidePosition,
      webstoryNextSlideCta: formData.webstoryNextSlideCta,
      // updateFlagWebStory: false,

      immStoryLogo: formData.immStoryLogo,
      // immStoryLogoHeight: formData.immStoryLogoHeight,
      immStoryGaId: formData.immStoryGaId,
      immStoryGa4Id: formData.immStoryGa4Id,
      // updateFlagImmersiveStory: false,

      facebook: getUrlByName(facebookLabel),
      twitter: getUrlByName(twitterLabel),
      youtube: getUrlByName(youTubeLabel),
      linkedIn: getUrlByName(linkedInLabel),
      instagram: getUrlByName(instagramLabel),
      pinterest: getUrlByName(pinterestLabel),
      playstore: getUrlByName(playStoreLabel)
    }));
  }, [clicked]);

  const { headerScript, bodyScript, facebook, twitter, youtube, linkedIn, instagram, pinterest, playstore, ...formDataToSend } = formData;
  const finalFormDataToSend = {
    ampHeaderScript: encodeHTML(formData.ampHeaderScript),
    ampBodyScript: encodeHTML(formData.adsSlots),
    websiteTitle: encodeHTML(formData.websiteTitle),
    tagline: encodeHTML(formData.tagline),
    siteDescription: encodeHTML(formData.siteDescription),
    copyRightContent: encodeHTML(formData.copyRightContent),
    ...formDataToSend
  };

  const sanitizeGoogleVerificationInput = (value) => {
    const sanitizedValue = value.replace(/[^a-zA-Z0-9-_.]/g, '');
    if (/^[0-9.]/.test(sanitizedValue)) {
      return sanitizedValue.slice(1);
    }
    return sanitizedValue.substring(0, 43);

  };

  const handleSubAccordionChange = (index) => {
    setSubExpandedAccordion(subexpandedAccordion === index ? null : index);
  };

  const handleStaticPagesChange = (e) => {
    const val = e.target.value;
    const index = e.target.getAttribute("index");
    let staticPageArray = formData?.staticPages;
    const label = e.target.getAttribute("label");
    if (label === 'static name') {
      staticPageArray[index].name = val;
    } else if (label === 'static source path') {
      staticPageArray[index].sourcePath = val;
    } else if (label === 'static destination path') {
      const numericVal = val.replace(/\D/g, '');
      staticPageArray[index].destinationPath = '/staticpage/' + numericVal + '.html';
    }
    staticPageArray[index].update = true;
    setFormData((prevData) => ({
      ...prevData,
      staticPages: staticPageArray,
    }));
  }

  const handleBlur = (e) => {
    const val = e.target.value;
    const index = e.target.getAttribute("index");
    let staticPageArray = formData?.staticPages;
    const label = e.target.getAttribute("label");

    if (label === 'static destination path') {
      // Allow only numeric characters
      const numericVal = val.replace(/\D/g, '');
      const name = staticPageArray[index].name;
      validateMsid(name, numericVal);
    }
  };

  const handleAddStaticPage = () => {
    const staticPageArray = formData?.staticPages;
    staticPageArray?.push({ name: 'New Static Page', sourcePath: '', destinationPath: '' })
    setFormData((prevData) => ({
      ...prevData,
      staticPages: staticPageArray,
    }));
  }

  const deleteStaticPage = (subItem, subIndex) => {
    formData?.staticPages?.splice(subIndex, 1);
    const staticPageArray = formData?.staticPages;
    setFormData((prevData) => ({
      ...prevData,
      staticPages: staticPageArray,
    }));
  }

  return (
    <div>
      <Accordion expanded={expanded[0]} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-headerNbody" id="panel-headerNbody">
          <Typography>HEADER & BODY SCRIPTS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Header Scripts</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            fullWidth
            id="global-header-script"
            style={{ marginBottom: '8px' }}
            value={formData.headerScript || []}
            onChange={(e) => handleScriptChange('headerScript', e.target.value)}
          />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Body Scripts</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            fullWidth
            id="global-body-script"
            value={formData.bodyScript || ''}
            onChange={(e) => handleScriptChange('bodyScript', e.target.value)} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded[1]} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-amp-configuration"
          id="panel-amp-configuration"
        >
          <Typography variant="h6" style={{ display: 'flex' }}>
            WEBSTORY CONFIGURATION
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControlLabel
            style={{
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginLeft: '0px',
            }}
            checked={formData.webStoryAds}
            control={<Switch color="primary" style={{ marginRight: '-100px' }} />}
            onChange={(e) => handleInputChange('webStoryAds', e.target.checked)}
            label={<Typography variant="h6" style={{ fontWeight: 'bold' }}>Webstory Ads</Typography>}
            labelPlacement="start"
          />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Header Script</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            fullWidth
            id="amp-header-script"
            style={{ marginBottom: '8px' }}
            value={formData.ampHeaderScript || []}
            onChange={(e) => handleInputChange('ampHeaderScript', e.target.value)}
          />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Body Script</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            fullWidth
            id="amp-body-script"
            value={formData.adsSlots || ''}
            onChange={(e) => handleInputChange('adsSlots', e.target.value)}
            style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Webstory Logo*</b>
          </Typography>
          <TextField value={formData.webstoryLogo || ''} onChange={(e) => handleInputChange('webstoryLogo', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Default Author*</b>
          </Typography>
          <TextField value={formData.webstoryDefaultAuthor || ''} onChange={(e) => handleInputChange('webstoryDefaultAuthor', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Default Agency Name*</b>
          </Typography>
          <TextField value={formData.webstoryDefaultAgencyName || ''} onChange={(e) => handleInputChange('webstoryDefaultAgencyName', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>GA ID</b>
          </Typography>
          <TextField value={formData.gaId || ''} onChange={(e) => handleInputChange('gaId', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>GA4 ID</b>
          </Typography>
          <TextField value={formData.ga4Id || ''} onChange={(e) => handleInputChange('ga4Id', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA Description*</b>
          </Typography>
          <TextField value={formData.ctaDesc || ''} onChange={(e) => handleInputChange('ctaDesc', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA BG Color</b>
          </Typography>
          <ColorPicker value={formData.ctaBgColor || ''} onChange={(colorResult) => handleColorInputChange('ctaBgColor', colorResult)} fullWidth />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA Text*</b>
          </Typography>
          <TextField value={formData.ctaBtnText || ''} onChange={(e) => handleInputChange('ctaBtnText', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA Text Color</b>
          </Typography>
          <ColorPicker value={formData.ctnBtnBgColor || ''} onChange={(colorResult) => handleColorInputChange('ctnBtnBgColor', colorResult)} fullWidth />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA NextSlide </b>
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="nextslide-select-label"
              id="nextslide-select"
              style={{ textAlign: 'left' }}
              value={formData.ctaNextSlide || "Read Next"}
              onChange={(e) => handleInputChange('ctaNextSlide', e.target.value)}
            >
              <MenuItem value={'Read Next'}>Read Next</MenuItem>
              <MenuItem value={'See more stories'}>See more stories</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>CTA Next Slide Message</b>
          </Typography>
          <TextField value={formData.ctaNextSlideMsg || ''} onChange={(e) => handleInputChange('ctaNextSlideMsg', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Custom Slide Position</b>
          </Typography>
          <TextField value={formData.webstoryCustomSlidePosition || ''} onChange={(e) => handleInputChange('webstoryCustomSlidePosition', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Next Slide CTA</b>
          </Typography>
          <TextField value={formData.webstoryNextSlideCta || ''} onChange={(e) => handleInputChange('webstoryNextSlideCta', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
            
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded[5]} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-immersivestory-configuration"
          id="panel-immersivestory-configuration"
        >
          <Typography variant="h6" style={{ display: 'flex' }}>
            IMMERSIVE STORY CONFIGURATION
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

        <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Immersive Story Logo*</b>
          </Typography>
          <TextField value={formData.immStoryLogo || ''} onChange={(e) => handleInputChange('immStoryLogo', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          {/* <Typography variant="h6" style={{ display: 'flex' }}>
            <b>LOGO Height*</b>
          </Typography>
          <TextField value={formData.immStoryLogoHeight || ''} onChange={(e) => handleInputChange('immStoryLogoHeight', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} /> */}

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>GA ID</b>
          </Typography>
          <TextField value={formData.immStoryGaId || ''} onChange={(e) => handleInputChange('immStoryGaId', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>GA4 ID</b>
          </Typography>
          <TextField value={formData.immStoryGa4Id || ''} onChange={(e) => handleInputChange('immStoryGa4Id', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          {/* <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Site URL</b>
          </Typography>
          <TextField value={formData.immStorySiteUrl || ''} onChange={(e) => handleInputChange('immStorySiteUrl', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} /> */}

        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded[2]} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-site-resources"
          id="panel-site-resources"
        >
          <Typography variant="h6" style={{ display: 'flex' }}>
            SITE RESOURCES
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Site Title*</b>
          </Typography>
          <TextField value={formData.websiteTitle || ''} onChange={(e) => handleInputChange('websiteTitle', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }}
          />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Site Tagline</b>
          </Typography>
          <TextField value={formData.tagline || ''} onChange={(e) => handleInputChange('tagline', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Site Description</b>
          </Typography>
          <TextField value={formData.siteDescription || ''} onChange={(e) => handleInputChange('siteDescription', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Logo*</b>
          </Typography>
          <TextField value={formData.mainLogo || ''} onChange={(e) => handleInputChange('mainLogo', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Favicon*</b>
          </Typography>
          <TextField value={formData.favicon || ''} onChange={(e) => handleInputChange('favicon', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Fallback Image</b>
          </Typography>
          <TextField value={formData.fallbackImage || ''} onChange={(e) => handleInputChange('fallbackImage', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Schema Logo*</b>
          </Typography>
          <TextField value={formData.schemaLogo || ''} onChange={(e) => handleInputChange('schemaLogo', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Footer Logo</b>
          </Typography>
          <TextField value={formData.footerLogo || ''} onChange={(e) => handleInputChange('footerLogo', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Ads.txt</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            id="adstxt"
            fullWidth
            style={{ marginBottom: '8px' }}
            value={formData.adsTxtFile || []}
            onChange={(e) => handleInputChange('adsTxtFile', e.target.value)}
          />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Robots.txt</b>
          </Typography>
          <TextField
            InputProps={{
              rows: 2,
              multiline: true,
              inputComponent: 'textarea',
            }}
            variant="outlined"
            id="robotstxt"
            fullWidth
            style={{ marginBottom: '8px' }}
            value={formData.robotsTxt || []}
            onChange={(e) => handleInputChange('robotsTxt', e.target.value)}
          />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Copyright Content</b>
          </Typography>
          <TextField value={formData.copyRightContent || ''} onChange={(e) => handleInputChange('copyRightContent', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Contact us</b>
          </Typography>
          <TextField value={formData.contactUs || ''} onChange={(e) => handleInputChange('contactUs', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Support Email</b>
          </Typography>
          <TextField value={formData.supportEmail || ''} onChange={(e) => handleInputChange('supportEmail', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Image Agency </b>
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="agency-select-label"
              id="agency-select"
              style={{ textAlign: 'left' }}
              value={formData.imageAgencyLabel || "Image Credit"}
              onChange={(e) => handleInputChange('imageAgencyLabel', e.target.value)}
            >
              <MenuItem value={'image_credit'}>Image Credit</MenuItem>
              <MenuItem value={'image_source'}>Image Source</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Site Language*</b>
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="language-select-label"
              id="language-select"
              style={{ textAlign: 'left' }}
              value={formData.siteLanguage || "Select"}
              onChange={(e) => handleInputChange('siteLanguage', e.target.value)}
            >
              {SITE_LANGUAGES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Keywords</b>
          </Typography>
          <TextField value={formData.keywords || ''} onChange={(e) => handleInputChange('keywords', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>About Site Content</b>
          </Typography>
          <TextField value={formData.aboutSiteContent || ''} onChange={(e) => handleInputChange('aboutSiteContent', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Theme Color</b>
          </Typography>
          <ColorPicker value={formData.themeColor || ''} onChange={(colorResult) => handleColorInputChange('themeColor', colorResult)} fullWidth />
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Google Site Verification</b>
          </Typography>
          <TextField value={formData.googleSiteVerification || ''}
            onChange={(e) => {
              const santVal = sanitizeGoogleVerificationInput(e.target.value);
              handleInputChange('googleSiteVerification', santVal);
            }}
            variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded[3]} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-social-links"
          id="panel-social-links"
        >
          <Typography variant="h6" style={{ display: 'flex' }}>
            SOCIAL LINKS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Facebook</b>
          </Typography>
          <TextField value={formData.facebook || ''} onChange={(e) => handleUrlChange('facebook', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />


          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>X (Formally Twitter)</b>
          </Typography>
          <TextField value={formData.twitter || ''} onChange={(e) => handleUrlChange('twitter', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Youtube</b>
          </Typography>
          <TextField value={formData.youtube || ''} onChange={(e) => handleUrlChange('youtube', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>LinkedIn</b>
          </Typography>
          <TextField value={formData.linkedIn || ''} onChange={(e) => handleUrlChange('linkedIn', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Instagram</b>
          </Typography>
          <TextField value={formData.instagram || ''} onChange={(e) => handleUrlChange('instagram', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Pinterest</b>
          </Typography>
          <TextField value={formData.pinterest || ''} onChange={(e) => handleUrlChange('pinterest', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />

          <Typography variant="h6" style={{ display: 'flex' }}>
            <b>Playstore</b>
          </Typography>
          <TextField value={formData.playstore || ''} onChange={(e) => handleUrlChange('playstore', e.target.value)} variant="outlined" fullWidth style={{ marginBottom: '8px' }} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded[4]} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-social-links"
          id="panel-social-links"
        >
          <Typography variant="h6" style={{ display: 'flex' }}>
            STATIC PAGES
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {formData.staticPages && formData.staticPages.map((subItem, subIndex) => (
            <div style={{ border: '1px solid #cccccc' }}>
              <Accordion key={subIndex} expanded={subexpandedAccordion === subIndex} onChange={() => handleSubAccordionChange(subIndex)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{subItem.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography variant="h6" align="left">Display Name:</Typography>
                    <div>
                      <input type="text" className='inputBoxStyle' label="static name" index={subIndex} value={subItem?.name} maxLength={100} onChange={(e) => handleStaticPagesChange(e)} />
                    </div>
                    <Typography variant="h6" align="left">Short URL:</Typography>
                    <div>
                      <input type="text" className='inputBoxStyle' label="static source path" index={subIndex} value={subItem?.sourcePath} maxLength={100} onChange={(e) => handleStaticPagesChange(e)} />
                    </div>
                    <Typography variant="h6" align="left">MSID of the static article:</Typography>
                    <div>
                      <input type="number" className='inputBoxStyleNumber' label="static destination path" index={subIndex} value={subItem?.destinationPath?.match(/\d+/g)?.join('')} maxLength={100} onBlur={(e) => handleBlur(e)} onChange={(e) => handleStaticPagesChange(e)} onWheel={(e) => e.target.blur()} />
                    </div>
                    <div className='deleteIcon1'>
                      {/* <i className="fas fa-trash-alt" onClick={() => deleteStaticPage(subItem, subIndex)}></i> */}
                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteStaticPage(subItem, subIndex)} />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" onClick={() => handleAddStaticPage()} size="small" style={{ minWidth: '80px', minHeight: '24px', fontSize: '0.55rem', marginTop: '10px' }}>+ Add Static Page</Button>
          </div>
        </AccordionDetails>
      </Accordion>
      <Button style={{ marginTop: '20px' }} variant="outlined" onClick={handleButtonClick} {...(isSaving && { disabled: true })}>Save/Update</Button>
    </div>
  );
}


export default GlobalSettingComponent;
